import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"; // pozwala na wykrycie języka z przeglądarki
import enTranslations from "./en.json";
import plTranslations from "./pl.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ["path", "navigator"] },
    resources: {
      pl: { translation: plTranslations },
      en: { translation: enTranslations },
    },
    lng: 'pl',
    fallbackLng: "pl",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
