import styled from "styled-components";
import { MainContainer } from "../MainContainer";

export const FooterContents = styled(MainContainer)`
  display: flex;
  gap: 40px;
  margin-top: 60px;
  margin-bottom: 60px;
  @media (max-width: 64em) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 40px;
  }
`;
