import React from "react";
import styled from "styled-components";
import EmptyPreviousVideoContainer from "./EmptyPreviousVideoContainer";

const RightColumn = styled.div`
  width: 100%;
`;

const EmptyPreviousVideosColumn = (props) => {
  return (
    <RightColumn>
      <EmptyPreviousVideoContainer />
      <EmptyPreviousVideoContainer />
      <EmptyPreviousVideoContainer />
      <EmptyPreviousVideoContainer />
    </RightColumn>
  );
};

export default EmptyPreviousVideosColumn;
