import React from "react";
import styled from "styled-components";

export const DonationContainer = styled.div`
  width: 251px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 64em) {
    width: 100%;
  }
`;

export const DonationImg = styled.img`
  @media (max-width: 64em) {
    max-width: 100%;
    height: auto;
  }
`;

export const DonationText = styled.span`
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(18, 30, 60, 1);
  text-align: center;
`;

const Donation = (props) => {
  return (
    <DonationContainer>
      <DonationImg src={props.src} />
      <DonationText>{props.name}</DonationText>
    </DonationContainer>
  );
};

export default Donation;
