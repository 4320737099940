import styled from "styled-components";

export const FooterMenuItem = styled.a`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  &:hover {
    color: #f08a67;
  }
`;
