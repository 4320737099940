// src/globalStyles.js
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    padding: 0;
    line-height: 1.2;
    font-size: $font_medium;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }
  
  h1,
  h2,
  h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  a,
  a:visited,
  a:hover {
    text-decoration: none;
    color: inherit;
  }
`;

export default GlobalStyle;
