import React from "react";
import styled from "styled-components";
import MainVideo from "./MainVideo";
import { pulse } from "../../animations/pulse";

const LeftContainer = styled.div`
  width: 100%;
  margin-right: 20px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  @media (max-width: 64em) {
    width: 100%;
    align-items: center;
  }
`;

const Title = styled.span`
  font-family: Merriweather;
  font-size: 31.25px;
  font-weight: 700;
  line-height: 39.28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(18, 30, 60, 1);
  align-self: flex-start;
  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const EmptyTitle = styled.div`
  width: 100%;
  height: 39.28px;
  background: rgba(217, 217, 217, 1);
  animation: ${pulse} 1s infinite alternate;
  @media (max-width: 500px) {
    height: 28px;
  }
`;

const EmptyDescription = styled.div`
  width: 100%;
  height: 65.37px;
  background: rgba(217, 217, 217, 1);
  animation: ${pulse} 1s infinite alternate;
  align-self: flex-start;
  @media (max-width: 64em) {
    margin-bottom: 20px;
  }
`;

const Description = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(18, 30, 60, 1);
  align-self: flex-start;
  @media (max-width: 64em) {
    margin-bottom: 20px;
  }
`;

const MainVideoColumn = (props) => {
  return (
    <LeftContainer>
      <MainVideo
        src={
          props.video?.mediaSources[0] ? props.video?.mediaSources[0].url : null
        }
      />
      <Title>{props.video?.title ? props.video.title : ""}</Title>
      {!props.video?.title && <EmptyTitle></EmptyTitle>}
      <Description>
        {props.video?.description ? props.video.description : ""}
      </Description>
      {!props.video?.description && <EmptyDescription />}
    </LeftContainer>
  );
};

export default MainVideoColumn;
