import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import MainVideoColumn from "./videos/MainVideoColumn";
import PreviousVideosColumn from "./videos/PreviousVideosColumn";
import axios from "axios";
import EmptyPreviousVideosColumn from "./videos/EmptyPreviousVideosColumn";
import Slider from "react-slick";

const SerieDiv = styled.div`
  display: flex;
  margin-top: 213px;
  margin-bottom: 110px;
  @media (max-width: 64em) {
    flex-direction: column;
    align-items; center;
    margin-top: 160px;
    margin-bottom: 40px;
  }
  @media (max-width: 500px) {
    margin-top: 137px;
  }
`;

const Serie = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.tridentoffreedom.com/episode/latest")
      .then((response) => {
        setVideos(response.data);
      })
      .catch((error) => console.error("Error fetching videos:", error));
  }, []);

  const onVideoPress = (videoId) => {
    axios
      .get("http://localhost:3000/episode")
      .then((response) => setVideos(response.data))
      .catch((error) => console.error("Error fetching episode:", error));
  };

  const otherVideos = useMemo(
    () =>
      videos && Object.values(videos) ? Object.values(videos).slice(1) : [],
    [videos]
  );

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <SerieDiv>
      <MainVideoColumn video={videos[0]} />
      {/* {videos[0] && (
        <PreviousVideosColumn
          videos={otherVideos ? otherVideos : []}
          onVideoPress={onVideoPress}
        />
      )}
      {!videos[0] && <EmptyPreviousVideosColumn />} */}
    </SerieDiv>
  );
};

export default Serie;
