import styled from "styled-components";

export const MenuText = styled.div`
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(255, 255, 255, 1);
`;
