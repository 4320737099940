import React from "react";
import styled from "styled-components";

const PrevMovie = styled.div`
  width: 180px;
  height: 106px;
  background: rgba(217, 217, 217, 1);
`;

const PreviousVideo = (props) => {
  return (
    <PrevMovie>
      <img src={props.thumbnail} style={{ width: 180, height: 106 }} />
    </PrevMovie>
  );
};

export default PreviousVideo;
