import styled from "styled-components";

export const CopyRight = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(18, 30, 60, 1);
  align-self: center;
  margin: 20px;
`;
