import React from "react";
import styled from "styled-components";
import { PrevMovieContainer } from "./shared/PrevMovieContainer";
import { PrevMovieContainerRight } from "./shared/PrevMovieContainerRight";
import { pulse } from "../../animations/pulse";

const EmptyPreviousVideo = styled.div`
  width: 180px;
  height: 106px;
  background: rgba(217, 217, 217, 1);
  animation: ${pulse} 1s infinite alternate;
`;

const EmptyMovieText = styled.div`
  width: 100%;
  height: 21.79px;
  background: rgba(217, 217, 217, 1);
  margin: 2px;
  animation: ${pulse} 1s infinite alternate;
`;

const EmptyPreviousVideoContainer = (props) => {
  return (
    <PrevMovieContainer>
      <EmptyPreviousVideo></EmptyPreviousVideo>
      <PrevMovieContainerRight>
        <EmptyMovieText />
        <EmptyMovieText />
      </PrevMovieContainerRight>
    </PrevMovieContainer>
  );
};

export default EmptyPreviousVideoContainer;
