import React from "react";
import styled from "styled-components";

export const AvatarColumn = styled.div`
  width: 251px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  width: 236px;
  height: 236px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
`;

export const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const Avatar = () => {
  return (
    <AvatarColumn>
      <AvatarContainer>
        <AvatarImg src="images/avatar.jpg" />
      </AvatarContainer>
    </AvatarColumn>
  );
};

export default Avatar;
