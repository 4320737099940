import { keyframes } from "styled-components";

export const pulse = keyframes`
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
`;
