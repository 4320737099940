import React from "react";
import styled from "styled-components";
import { FooterContents } from "./footer/FooterContents";
import { AuthorTexts } from "./footer/AuthorTexts";
import { AuthorName } from "./footer/AuthorName";
import { AuthorDescription } from "./footer/AuthorDescription";
import Avatar from "./footer/Avatar";
import { InnerFooter } from "./footer/InnerFooter";
import { InnerFooterContainer } from "./footer/InnerFooterContainer";
import { MailSocials } from "./footer/MailSocials";
import { SocialItems } from "./footer/SocialItems";
import { Mail } from "./footer/Mail";
import { MenuText } from "./footer/MenuText";
import { FooterMenuItems } from "./footer/FooterMenuItems";
import { FooterMenuItem } from "./footer/FooterMenuItem";
import { CopyRight } from "./footer/CopyRight";
import { useTranslation } from "react-i18next";

const FooterDiv = styled.div`
  margin: 0;
  padding: 0;
  background: rgba(81, 192, 216, 1);
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MenuDiv = styled.div`
  width: 383px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const FooterImg = styled.img`
  @media (max-width: 500px) {
    width: 90%;
    height: auto;
  }
`;

const Footer = () => {
  const { t, i18n } = useTranslation();

  const onItemClick = (event, name) => {
    event.preventDefault();
    const donationsSection = document.getElementById(name);
    const menu = document.getElementById("hd");
    const menuHeight = menu ? menu.offsetHeight : 0;

    window.scrollTo({
      top: donationsSection.offsetTop - menuHeight,
      behavior: "smooth",
    });
  };

  return (
    <FooterDiv>
      <FooterContents>
        <AuthorTexts>
          <AuthorName>Arek Chrobot</AuthorName>
          <AuthorDescription>{t("author_description")}</AuthorDescription>
        </AuthorTexts>
        <Avatar />
      </FooterContents>
      <InnerFooter>
        <InnerFooterContainer>
          <FooterImg src="images/logo_poziom_slogan_uproszczony.png" />
          <MailSocials>
            <Mail href="mailto:tof@tridentoffreedom.com">
              tof@tridentoffreedom.com
            </Mail>
            {/* <SocialItems>
              <a href="https://facebook.com">
                <img src="images/orange_icon_face.png" />
              </a>
              <a href="https://facebook.com">
                <img src="images/orange_icon_insta.png" />
              </a>
            </SocialItems> */}
          </MailSocials>
          <MenuDiv>
            <MenuText>MENU</MenuText>
            <FooterMenuItems>
              <FooterMenuItem onClick={(event) => onItemClick(event, "series")}>
                {t("series")}
              </FooterMenuItem>
              <FooterMenuItem onClick={(event) => onItemClick(event, "about")}>
                {t("bio")}
              </FooterMenuItem>
              <FooterMenuItem
                onClick={(event) => onItemClick(event, "donations")}
              >
                {t("donations")}
              </FooterMenuItem>
            </FooterMenuItems>
          </MenuDiv>
        </InnerFooterContainer>
      </InnerFooter>
      <CopyRight>© Arek Chrobot 2024</CopyRight>
    </FooterDiv>
  );
};

export default Footer;
