import logo from "./logo.svg";
import "./App.css";
import GlobalStyle from "./globalStyles";
import Header from "./components/Header";
import Serie from "./components/Serie";
import styled from "styled-components";
import { MainContainer } from "./components/MainContainer";
import About from "./components/About";
import Donations from "./components/Donations";
import Footer from "./components/Footer";
import i18n from "./i18n/i18n";
import Slider from "react-slick";

function App() {
  return (
    <>
      <GlobalStyle />
      <div>
        <Header />
        <MainContainer id="series">
          <Serie />
        </MainContainer>
        <About />
        <Donations />
        <Footer />
      </div>
    </>
  );
}

export default App;
