import Hamburger from "hamburger-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;  // Początkowo niewidoczny
  }
  to {
    opacity: 1;  // Element staje się widoczny
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;  // Początkowo niewidoczny
  }
  to {
    opacity: 0;  // Element staje się widoczny
  }
`;

const Container = styled.header`
  display: flex;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  flex-direction: column;
`;
const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  height: 103px;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background: white;
  box-shadow: 0px 7px 20px 0px rgba(18, 30, 60, 0.25);

  @media (max-width: 500px) {
    padding: 10px;
    height: 70px;
  }
`;

const Logo = styled.a`
  flex: 1;
  img {
    @media (max-width: 64em) {
      width: 250px;
      height: auto;
    }
  }
  cursor: pointer;
`;

const ItemsContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
  @media (max-width: 64em) {
    display: none;
  }
`;

const MenuItems = styled.div`
  display: flex;
  gap: 40px;
`;

const MenuItem = styled.a`
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(18, 30, 60, 1);
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: #f08a67;
  }
`;

const HamburgerContainer = styled.div`
  display: none;
  @media (max-width: 64em) {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
`;

const MobileMenu = styled.li`
  background-color: white;
  box-shadow: 0px 8px 20px rgba(200, 200, 200, 0.6);
  -webkit-box-shadow: 0px 8px 20px rgba(200, 200, 200, 0.6);
  -moz-box-shadow: 0px 8px 20px rgba(200, 200, 200, 0.6);
  display: none;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  opacity: 0;

  &.open {
    display: flex;
    opacity: 1;
  }

  &.closed {
    display: flex;
    opacity: 0;
  }
`;

const Header = () => {
  const { t, i18n } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const onItemClick = (event, name) => {
    setOpen(false);
    event.preventDefault();
    const donationsSection = document.getElementById(name);
    const menu = document.getElementById("hd");
    const menuHeight = menu ? menu.offsetHeight : 0;

    window.scrollTo({
      top: donationsSection.offsetTop - menuHeight,
      behavior: "smooth",
    });
  };

  const onLogoClick = (event) => {
    setOpen(false);
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container>
      <HeaderContainer id="hd">
        <Logo onClick={onLogoClick}>
          <img src="images/logo_left.png" alt="Logo" />
        </Logo>
        <div />
        <ItemsContainer>
          <MenuItems>
            <MenuItem onClick={(event) => onItemClick(event, "series")}>
              {t("series")}
            </MenuItem>
            <MenuItem onClick={(event) => onItemClick(event, "about")}>
              {t("bio")}
            </MenuItem>
            <MenuItem onClick={(event) => onItemClick(event, "donations")}>
              {t("donations")}
            </MenuItem>
          </MenuItems>
          {/* <SocialItems>
          <a href="https://facebook.com">
            <img src="images/icon_fb.png" />
          </a>
          <a href="https://facebook.com">
            <img src="images/icon_insta.png" />
          </a>
        </SocialItems> */}
        </ItemsContainer>
        <HamburgerContainer>
          <Hamburger toggled={isOpen} toggle={setOpen} color="#51C1D9" />
        </HamburgerContainer>
      </HeaderContainer>
      <MobileMenu className={isOpen ? "open" : "closed"}>
        <MenuItem
          onClick={(event) => onItemClick(event, "series")}
          style={{ display: isOpen ? "block" : "none" }}
        >
          {t("series")}
        </MenuItem>
        <MenuItem
          onClick={(event) => onItemClick(event, "about")}
          style={{ display: isOpen ? "block" : "none" }}
        >
          {t("bio")}
        </MenuItem>
        <MenuItem
          onClick={(event) => onItemClick(event, "donations")}
          style={{ display: isOpen ? "block" : "none" }}
        >
          {t("donations")}
        </MenuItem>
      </MobileMenu>
    </Container>
  );
};

export default Header;
