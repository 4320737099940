import styled from "styled-components";

export const AboutTitle = styled.span`
  color: rgba(18, 30, 60, 1);
  font-family: Merriweather;
  font-size: 48.83px;
  font-weight: 700;
  line-height: 61.38px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  @media (max-width: 64em) {
    text-align: center;
  }
  @media (max-width: 500px) {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 10px;
  }
`;
