import styled from "styled-components";
import { MainContainer } from "../MainContainer";

export const AboutInnerContainer = styled(MainContainer)`
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: center;
  @media (max-width: 64em) {
    flex-direction: column;
  }
`;
