import styled from "styled-components";

export const DonationsDescription = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(18, 30, 60, 1);
`;
