import React from "react";
import styled from "styled-components";
import { AboutInnerContainer } from "./about/AboutInnerContainer";
import { TextsContainer } from "./about/TextsContainer";
import { AboutTitle } from "./about/AboutTitle";
import { AboutDescription } from "./about/AboutDescription";
import { useTranslation } from "react-i18next";

const AboutDiv = styled.div`
  width: 100%;
  background: rgba(242, 250, 252, 1);
  box-shadow: 0px 7px 20px 0px rgba(18, 30, 60, 0.25);
  padding-top: 60px;
  padding-bottom: 50px;
  margin-bottom: 60px;
  @media (max-width: 64em) {
    padding-top: 50px;
    padding-bottom: 30px;
    margin-bottom: 40px;
  }
`;

const StyledImg = styled.img`
  @media (max-width: 500px) {
    width: 50%;
    height: auto;
  }
`;

const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <AboutDiv id="about">
      <AboutInnerContainer>
        <StyledImg src="images/logo_sygnet_color.png" />
        <TextsContainer>
          <AboutTitle>{t("proj")}</AboutTitle>
          <AboutDescription>{t("proj_description")}</AboutDescription>
        </TextsContainer>
      </AboutInnerContainer>
    </AboutDiv>
  );
};

export default About;
