import styled from "styled-components";
import { MainContainer } from "../MainContainer";

export const InnerFooterContainer = styled(MainContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 64em) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    gap: 40px;
  }
`;
