import styled from "styled-components";

export const DonationsTitle = styled.span`
  font-family: Merriweather;
  font-size: 48.83px;
  font-weight: 700;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(239, 137, 103, 1);
  @media (max-width: 500px) {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: -5px;
  }
`;
