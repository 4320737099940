import React from "react";
import styled from "styled-components";
import { MainContainer } from "./MainContainer";
import { DonationsTitle } from "./donations/DonationsTitle";
import { DonationsDescription } from "./donations/DonationsDescription";
import { CodesConatiner } from "./donations/CodesContainer";
import Donation from "./donations/Donation";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

const DonationsDiv = styled(MainContainer)`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 30px;
  padding-top: 40px;
  padding-bottom: 110px;
  @media (max-width: 64em) {
    padding-top: 0px;
    padding-bottom: 70px;
  }
`;

const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

const SliderDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SC = styled.div`
  display: none;
  @media (max-width: 64em) {
    display: block;
  }
`;

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

const Donations = () => {
  const { t, i18n } = useTranslation();
  return (
    <DonationsDiv id="donations">
      <DonationsTitle>{t("donations")}</DonationsTitle>
      <DonationsDescription>{t("donations_description")}</DonationsDescription>
      <CodesConatiner>
        <Donation src="images/solana.png" name="Solana" />
        <Donation src="images/bitcoin.png" name="Bitcoin" />
        <Donation src="images/litecoin.png" name="Litecoin" />
        <Donation src="images/ethereum.png" name="Ethereum" />
      </CodesConatiner>
      <SC>
        <Slider {...settings}>
          <SliderContainer>
            <SliderDiv>
              <Donation src="images/solana.png" name="Solana" />
            </SliderDiv>
          </SliderContainer>
          <SliderContainer>
            <SliderDiv>
              <Donation src="images/bitcoin.png" name="Bitcoin" />
            </SliderDiv>
          </SliderContainer>
          <SliderContainer>
            <SliderDiv>
              <Donation src="images/litecoin.png" name="Litecoin" />
            </SliderDiv>
          </SliderContainer>
          <SliderContainer>
            <SliderDiv>
              <Donation src="images/ethereum.png" name="Ethereum" />
            </SliderDiv>
          </SliderContainer>
        </Slider>
      </SC>
    </DonationsDiv>
  );
};

export default Donations;
