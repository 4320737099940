import styled from "styled-components";

export const AuthorTexts = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media (max-width: 64em) {
    margin-top: 0px;
    gap: 20px;
  }
`;
